<template>
    <section class="py-15 md:py-20 xl:py-22">
        <div class="mx-3.5 lg:mx-12.5 relative">
            <h3 class="text-blacks-100 text-3xl lg:text-40px xl:text-48px font-medium">{{ title }}</h3>
            <Swiper
                :modules="[SwiperAutoplay, SwiperPagination]"
                :breakpoints="{
                    300: { slidesPerView: 1.5 },
                    600: { slidesPerView: 2 },
                    800: { slidesPerView: 3 },
                    1020: { slidesPerView: 4 }
                }"
                :pagination="{
                    el: '.slide-pagination-product',
                    type: 'progressbar'
                }"
                :space-between="24"
                :speed="500"
                :grabCursor="true"
                class="w-full h-full mt-8 xl:mt-12 !relative !pb-12"
            >
                <SwiperSlide v-for="item in data">
                    <nuxt-link :to="item?.url" class="flex flex-col gap-6 group">
                        <div class="overflow-hidden">
                            <div class="aspect-1/1 max-h-sm overflow-hidden b-0.5 b-gray-200">
                                <!-- <pre>{{ item }}</pre> -->
                                <NuxtImg
                                    :src="
                                        getThumbnail(
                                            item?.images && item?.images.length > 0 ? item?.images[0] : '',
                                            450,
                                            true
                                        )
                                    "
                                    width="800"
                                    sizes="sm:100vw md:50vw lg:800px "
                                    loading="lazy"
                                    alt="Thumbnail Product"
                                    :placeholder="[50, 25, 75, 5]"
                                    class="w-full h-full object-cover duration-400 transform group-hover:scale-110"
                                />
                            </div>
                        </div>
                        <div>
                            <p class="text-blacks-100 text-lg font-medium hover:underline duration-300">
                                {{ item?.title }}
                            </p>
                            <div class="text-base text-blacks-70 mt-1 md:mt-2 box-des-product line-clamp-2">
                                {{ regexText(item?.description) }}
                            </div>
                        </div>
                    </nuxt-link>
                </SwiperSlide>
                <div class="z-10 absolute bottom-0 h-2.5 w-full rounded-20px">
                    <div class="slide-pagination-product"></div>
                </div>
            </Swiper>
        </div>
    </section>
</template>

<script setup lang="ts">
const props = defineProps({
    title: {
        type: String
    },
    data: {
        type: Array as any
    }
})
const regexText = (text: string) => {
    return text ? text.replace(/-/g, ' ') : ''
}
</script>

<style>
.slide-pagination-product.swiper-pagination-progressbar {
    @apply !h-2 rounded-20px;
}
.slide-pagination-product.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    @apply !bg-blacks-30  rounded-20px;
}
</style>
